import { css, cx } from "@emotion/css"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Hamburger from "./Hamburger/Hamburger"

const NavMenu = css`
  padding: 0px;
  & > * {
    margin-left: 20px;
  }
`

const MenuIcon = css`
  &:focus-visible {
    // outline: 2px solid #EBEDEE;
    outline-offset: 2px;
  }
`

const ToolTip = css`
  background: #ebedee;
  border-color: #cacaca;
  top: 70px;
  right: 17px;
  padding: 0;
  border-radius: 4px;
  position: absolute;
  margin-bottom: 10px;
  margin-left: -95px;
  z-index: 901;

  li {
    text-decoration: none;
    padding: 12px 34px;
    border-bottom: 2px solid #e1e1e1;
    list-style: none;
    margin: 0;

    &:last-child {
      border-bottom: none;
    }

    Button {
      text-decoration: none;
      list-style: none;
      border: none;
      margin: 0;
      padding: 0;
      font-size: 16px;
      background: inherit;
      color: rgb(51, 191, 159) none repeat scroll 0% 0%;
    }

    Button:hover {
      background: inherit;
      color: rgb(51, 191, 159) none repeat scroll 0% 0%;
    }
  }
`

const Hide = css`
  display: none;
`

const Menu = ({ children }) => {
  const [clicked, setClicked] = useState(false)
  const { t } = useTranslation()

  const onClickHandler = () => {
    setClicked(!clicked)
  }
  return (
    <div className={cx(NavMenu)}>
      <div
        className={cx(MenuIcon)}
        onClick={onClickHandler}
        // onKeyDown={(e) => runCallbackIfEnterPressed(e, onClickHandler)}
        role="button"
        aria-label={t("open-menu")}
        tabIndex={0}
      >
        <Hamburger isActive={clicked} toggleButton={onClickHandler} />
      </div>
      <ul className={clicked ? cx(ToolTip) : cx(Hide)}>{children}</ul>
    </div>
  )
}

export default Menu
