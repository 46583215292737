import { faBullseye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import Hamburger from "../../components/Menu/Menu"
import LoginControls from "../LoginControls"
import "./Navbar.css"

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: #333;
`

export default function Navbar(props) {
  const [clicked, setClicked] = useState(false)

  return (
    <div className="fixedPosition">
      <nav className="NavbarItems">
        <h1 className="navbar-logo">
          <Link to="/" aria-label="Kotisivulle" role="button">
            <StyledIcon icon={faBullseye} aria-hidden="true"></StyledIcon>
          </Link>
        </h1>
        <div
          className="menu-icon"
          onClick={() => setClicked(!clicked)}
          role="button"
          aria-label="Avaa valikko"
        >
          <Hamburger> </Hamburger>
        </div>
        <ul
          className={clicked ? "nav-menu active" : "nav-menu"}
          aria-expanded={clicked ? "true" : "false"}
          role="navigation"
        >
          <li>
            <a
              className="nav-links"
              href="/grid"
              aria-label="Edistymiseni"
              role="button"
            >
              Edistymiseni
            </a>
          </li>
          <LoginControls />
        </ul>{" "}
      </nav>{" "}
    </div>
  )
}
