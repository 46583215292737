// From https://github.com/AuvikAlive/react-animated-burgers/tree/master/src/lib/components/HamburgerSpring

import styled from "@emotion/styled"
import React from "react"
import { withTranslation } from "react-i18next"
import withSimpleErrorBoundary from "../../../util/withSimpleErrorBoundary"

const active = () => `
  transition-delay: 0.22s;
  background-color: transparent;
`

const getActiveBefore = () => `
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, ${30 * 0.1 + 30 * 0.15}px, 0) rotate(45deg);
`

const getActiveAfter = () => `
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, ${30 * 0.1 + 30 * 0.15}px, 0) rotate(-45deg);
`
const getLinesCommon = () => `
  width: 30px;
  height: ${30 * 0.1}px;
  border-radius: ${30 * 0.1}px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`

const StyledLines = styled.span`
  display: block;
  top: 50%;
  margin-top: ${-30 * 0.05}px;
  background-color: ${({ barColor }) => barColor};
  ${getLinesCommon(30)}

  &::before,
  &::after {
    background-color: ${({ barColor }) => barColor};
    ${getLinesCommon(30)}
    content: "";
    display: block;
  }

  &::before {
    top: ${-30 * 0.25}px;
  }

  &::after {
    bottom: ${-30 * 0.25}px;
  }
`

const StyledLinesSpring = styled(StyledLines)`
  &::before {
    top: ${30 * 0.1 + 30 * 0.15}px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${({ isActive }) => isActive && getActiveBefore(30)}
    background-color: ${({ barColor }) => barColor};
  }

  &::after {
    top: ${2 * 30 * 0.1 + 2 * 30 * 0.15}px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${({ isActive }) => isActive && getActiveAfter(30)}
    background-color: ${({ barColor }) => barColor};
  }

  top: ${30 * 0.1}px;
  transition: background-color 0s 0.13s linear;
  background-color: ${({ barColor }) => barColor};
  ${({ isActive }) => isActive && active}
`

const StyledButton = styled.button`
  padding: ${30 * 0.375}px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: ${({ buttonColor }) => buttonColor};
  margin: 0;
  border: none;
  overflow: visible;
`

const Button = ({ isActive, toggleButton }) => {
  const barColor = "#333"
  const buttonColor = "transparent"
  return (
    <StyledButton
      onClick={toggleButton}
      {...{ buttonColor }}
      id="main-navigation-menu"
    >
      <StyledBox>
        <StyledLinesSpring {...{ barColor, isActive }} />
      </StyledBox>
    </StyledButton>
  )
}

const StyledBox = styled.div`
  width: 30px;
  height: ${30 * 0.6}px;
  display: inline-block;
  position: relative;
`

const HamburgerSpring = (props) => <Button {...props} />

export default withTranslation("common")(
  withSimpleErrorBoundary(HamburgerSpring),
)
