import { css, cx } from "@emotion/css"
import React from "react"
import { withTranslation } from "react-i18next"
import withSimpleErrorBoundary from "../../../util/withSimpleErrorBoundary"
import HamburgerSpring from "./HamburgerSpring"

const defaultPadding = css`
  padding: 0;
`

const Hamburger = ({ isActive, toggleButton }) => {
  return (
    <HamburgerSpring
      barColor="#333"
      isActive={isActive}
      toggleButton={toggleButton}
      className={cx(defaultPadding)}
    />
  )
}

export default withTranslation("common")(withSimpleErrorBoundary(Hamburger))
